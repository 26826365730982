<template>
  <div class="accounting-details" v-if="fetched">
    <div class="vx-row mb-base ">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Abrechnung" class="h-full">
          <div class="vx-row text-lg">
            <div class="vx-col w-1/2 font-semibold">Kundennummer:</div>
            <div class="vx-col w-1/2">{{accounting.client.customer_number}}</div>
          </div>
          <div class="vx-row mt-4 text-lg">
            <div class="vx-col w-1/2 font-semibold">Firma:</div>
            <div class="vx-col  w-1/2 flex">
              <div class="vx-col w-1/2">  {{accounting.client.company_name}}</div>
              <div class="vx-col w-1/2">  <span class="link" @click="openClientSearch" color="primary" type="filled">Kunde wechseln</span> </div>
            </div>
          </div>
          <div class="vx-row text-lg mt-4">
            <div class="vx-col w-1/2 font-semibold">Email:</div>
            <div class="vx-col w-1/2 ">{{accounting.client.email}}</div>
          </div>
          <div class="vx-row text-lg mt-4">
            <div class="vx-col w-1/2 font-semibold">Plz & Ort:</div>
            <div class="vx-col w-1/2">{{accounting.client.zip_code}} {{client.city}}</div>
          </div>
          <div class="vx-row text-lg mt-4">
            <div class="vx-col w-1/2 font-semibold">Adressen Information 1:</div>
            <div class="vx-col w-1/2">{{accounting.client.address_line_1}}</div>
          </div>
          <div class="vx-row text-lg mt-4">
            <div class="vx-col w-1/2 font-semibold">Adressen Information 2:</div>
            <div class="vx-col w-1/2">{{accounting.client.address_line_2}}</div>
          </div>
          <div class="vx-row text-lg mt-4">
            <div class="vx-col w-1/2 font-semibold">Frühestens Abrechnen ab:</div>
            <div class="vx-col w-1/2 mb-4">
              <datepicker v-model="accounting.at_least_date_accounting" placeholder="Datum wählen"  :clearButton="true"></datepicker>
            </div>
          </div>
          <div class="vx-row text-lg mt-4" v-if="accounting.contract">
            <div class="vx-col w-1/2 font-semibold">Vertrag:</div>
            <div class="vx-col w-1/2">{{accounting.contract.contract_start}} -
              {{accounting.contract.contract_end}}
            </div>
          </div>
          <div class="vx-row text-lg mt-4" v-if="accounting.accounting_start">
            <div class="vx-col w-1/2 font-semibold">Abrechnungszeitraum:</div>
            <div class="vx-col w-1/2">{{accounting.accounting_start}} - {{accounting.accounting_end}}
            </div>
          </div>

          <div class="vx-row text-lg mt-4" v-if="accounting.order_id && accounting.order">
            <div class="vx-col w-1/2 font-semibold">Rechnungsnummer:</div>
            <div class="vx-col w-1/2" v-if="accounting.order.invoice_number" >{{accounting.order.invoice_number}}                            <vs-button color="dark" type="filled" icon="visibility" size="small" text="Zur Rechnung"
                                                                                                                                                        :to="'/invoices/view/'+accounting.order_id"  >Zur Rechnung</vs-button>
            </div>
            <div class="vx-col w-1/2" v-if="!accounting.order.invoice_number">
              <vs-button color="dark" type="filled" icon="visibility" size="small"
                         :to="'/invoices/view/'+accounting.order_id" >Zum Rechnungsentwurf</vs-button>
            </div>
          </div>

          <div class="vx-row text-lg mt-4" v-if="!accounting.offer_item_id && !accounting.offer_id">
            <div class="vx-col w-1/2 font-semibold">Angebot</div>
            <div class="vx-col w-1/2"><vs-button color="dark" type="border" size="small" text="Jetzt zuordnen"
                                                 @click="openOfferAllocationPopup">Jetzt zuordnen</vs-button></div>
          </div>
          <div class="vx-row text-lg mt-4" v-if="accounting.offer_item_id && accounting.offer_item">
            <div class="vx-col w-1/2 font-semibold">Angebotsposition:</div>
            <div class="vx-col w-1/2">
              <b>{{accounting.offer_item.product_name}}</b> ({{accounting.offer_item.amount}} {{unitName(accounting.offer_item.volume_unit)}})
              <div v-html="accounting.offer_item.short_description" style="font-size: 75%">{{accounting.offer_item.short_description}}</div>

              <div class="vx-row mt-3">
                <div v-if="accounting.offer_item.order.invoice_number" class="pt-2 vx-col w-1/4" >
                  <span class="mg-label"> Nr. {{accounting.offer_item.order.invoice_number}}</span>
                </div>
                <div v-if="accounting.offer_item.order.invoice_number" class="vx-col w-2/4" >
                  <vs-button color="dark" type="filled" icon="visibility" size="small" text="Zum Angebot"
                             :to="'/offers/'+accounting.offer_item.order_id+'/preview/'"  >Zum Angebot</vs-button>
                </div>
                <div v-if="accounting.offer_item.order.invoice_number" class="vx-col w-1/4" >
                  <vs-button color="danger" type="filled" icon="delete" size="small" text="Zum Angebot"
                             @click="clickDeleteOfferItemAllocation"  title="Angebotszuweisung löschen"></vs-button>

                </div>
              </div>

            </div>
          </div>

          <div class="vx-row text-lg mt-4" v-if="!accounting.b2b_licence_id">
            <div class="vx-col w-1/2 font-semibold">Lizenz:</div>
            <div class="vx-col w-1/2">
              <vs-button color="dark" type="border" size="small" text="Lizenz zuweisen"
                         @click="openLicenceAllocationPopup">Jetzt zuordnen</vs-button>
            </div>
          </div>


          <div class="vx-row text-lg mt-4" v-if="accounting.b2b_licence_id">
            <div class="vx-col w-1/2 font-semibold">Lizenz:</div>
            <div class="vx-col w-1/2 ">
              <div class="vx-row pl-4">
                <span>
                  <b>{{ accounting.b2b_licence.domain }}</b> ({{ accounting.b2b_licence.license_plan }})
                </span>
                <vs-button color="danger" type="filled" icon="delete" size="small"
                           @click="accounting.b2b_licence_id = null; saveAccounting();"
                           title="Lizenzzuweisung löschen">
                </vs-button>
              </div>

              <vs-button color="dark" type="filled" icon="visibility" size="small" text="Zur Lizenz"
                         :to="'/licence-manager/'+accounting.b2b_licence_id">Zur Lizenz</vs-button>

            </div>
          </div>

          <div class="vx-row text-lg mt-4" v-if="!accounting.offer_item_id && accounting.offer_id">
            <div class="vx-col w-1/2 font-semibold">Angebot:</div>
            <div class="vx-col w-1/2">
              <b>Angebot Nr. {{accounting.offer.invoice_number }} vom {{formatDate(accounting.offer.order_date)}}</b> <br>
              <i>{{accounting.offer.title}}</i>

              <div class="vx-row mt-3">
                <div v-if="accounting.offer.invoice_number" class="pt-2 vx-col w-1/4" >
                  <span class="mg-label"> Nr. {{accounting.offer.invoice_number}}</span>
                </div>
                <div v-if="accounting.offer.invoice_number" class="vx-col w-2/4" >
                  <vs-button color="dark" type="filled" icon="visibility" size="small" text="Zum Angebot"
                             :to="'/offers/'+accounting.offer_id+'/preview/'"  >Zum Angebot</vs-button>
                </div>
                <div v-if="accounting.offer.invoice_number" class="vx-col w-1/4" >
                  <vs-button color="danger" type="filled" icon="delete" size="small" text="Zum Angebot"
                             @click="clickDeleteOfferAllocation"  title="Angebotszuweisung löschen"></vs-button>

                </div>
              </div>

            </div>
          </div>

          <div class="vx-row text-lg mt-4" v-if="accounting.creator">
            <div class="vx-col w-1/2 font-semibold">Erstellt von:</div>
            <div class="vx-col w-1/2">{{accounting.creator.detail.first_name}}
              {{accounting.creator.detail.last_name}}
            </div>
          </div>

        </vx-card>
      </div>

      <div class="vx-col lg:w-1/2" >

        <vx-card title="Position" class="h-full">
          <div class="flex items-center justify-center">
            <vs-col vs-w="6" class="pr-4">
              <vs-input label="Produkt-Nr." name="ordernumber" v-model="accounting.ordernumber" readonly
                        class="w-full" data-vv-as="Produkt-Nr." v-validate="'required'"/>
            </vs-col>

            <vs-col vs-w="5" class="pt-4">
              <span class="link" @click="openArticleSearch" color="primary" type="filled" v-if="!accounting.order_id">Anderes Produkt auswählen</span>

            </vs-col>
            <vs-col vs-w="1" class="pt-6">
              <div class="hover:text-primary cursor-pointer" @click="openProductDetailModal" v-show="accounting.article_id">
                <feather-icon icon="InfoIcon" class="w-5 h-5"></feather-icon>
              </div>
            </vs-col>
          </div>

          <vs-input class="w-full mt-4" label="Artikelname" v-model="accounting.article_name" :disabled="accounting.order_id"/>

          <label class="vs-input--label mt-4 block">Beschreibung</label>

          <vue-editor name="short_description" v-model="accounting.short_description" :editorToolbar="toolbar" data-vv-as="Beschreibung" v-if="!accounting.order_id"/>
          <div style="border:1px solid rgba(0, 0, 0, 0.2);padding:15px" v-html="accounting.short_description" v-if="accounting.order_id" >
            {{accounting.short_description}}
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col lg:w-1/3">
              <vs-input class="w-full" label="Anzahl" v-model="accounting.amount" type="number"
                        step=".25" :disabled="accounting.order_id"/>
            </div>
            <div class="vx-col lg:w-1/3">
              <vs-input class="w-full" label="Einzelpreis" v-model="accounting.unit_price" v-currency="{
                              currency: 'EUR',
                              locale: 'de',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              min: 0,
                            }" :disabled="accounting.order_id"/>
            </div>
            <div class="vx-col lg:w-1/3">
              <vs-input class="w-full" v-bind:class="{ 'border-red': isEKPriceRequire && !accounting.ek_price }" label="Einkaufspreis" v-model="accounting.ek_price" v-currency="{
                              currency: 'EUR',
                              locale: 'de',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              min: 0,
                            }" />
            </div>

          </div>
          <div v-if="!accounting.ek_price && isEKPriceRequire"  class="vx-row ml-0 mr-0 mt-2">
            <div class="vs-alert con-icon offerError mb-4"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">error</i>Bitte verwende einen <b>Einkaufspreis</b>, falls vorhanden! </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col lg:w-1/2">
              <vs-select label="Einheit" v-model="accounting.volume_unit" class="w-full" :disabled="accounting.order_id" @change="onChangeVolumeUnit">
                <vs-select-item :key="index" :value="item.value" :text="item.label"
                                v-for="(item, index) in unitOptions"/>
              </vs-select>
            </div>
            <div class="vx-col lg:w-1/2">

              <vs-select label="MwSt." v-model="accounting.tax" class="w-full"  disabled="disabled">
                <vs-select-item :key="index" :value="item.value" :text="item.label"
                                v-for="(item, index) in taxOptions"/>
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-8">
            <div class="vx-col flex w-full justify-end">
              <div class="vx-row w-1/2">
                <div class="vx-col w-1/2 text-right text-lg">
                  Brutto Gesamt Preis:
                </div>
                <div class="vx-col w-1/2 text-right text-lg">
                  {{taxSum}} €
                </div>
              </div>
            </div>
            <div class="vx-col w-full flex justify-end mt-2">
              <div class="vx-row w-1/2">
                <div class="vx-col w-1/2 text-right text-lg font-semibold">
                  Netto Gesamt Preis:
                </div>
                <div class="vx-col w-1/2 text-right text-lg font-semibold">
                  {{sum}} €
                </div>
              </div>
            </div>
            <div class="vx-col w-full flex justify-end mt-2" v-if="margePercent != '100' && margePercentFloat != 'NaN'" >
              <div class="vx-row w-1/2">
                <div class="vx-col w-1/2 text-right ">
                  Marge:
                </div>
                <div class="vx-col w-1/2 text-right " v-bind:class="[margePercent < '20'  ? 'color-red' : 'color-green']">
                  {{margeAbs}} € ({{margePercentFloat}} %)
                </div>
              </div>
            </div>
          </div>

        </vx-card>
      </div>
    </div>

    <div class="vx-col lg:w-1/2">
      <vx-card title="Aufgabenverteilung" class="h-full">

        <task-allocation
            data-typ="accounting"
            :dataId="accounting"
            ref="TaskAllocationData">
        </task-allocation>

      </vx-card>
    </div>

    <div class="flex mt-8 justify-end">
      <vs-checkbox color="success" class="pr-2" v-if="(accounting.commission_option === true || accounting.commission_option === 1) && (accounting.commission_complete === false || accounting.commission_complete === 0)" v-model="accounting.commission_complete" @change="onCheckCommissionComplete">Aufgabenverteilung vollständig abschließen</vs-checkbox>
      <span class="pr-2 commission_complete" v-if="(accounting.commission_complete === true || accounting.commission_complete === 1)" >  <vs-icon icon="check_circle"></vs-icon> Aufgabenverteilung vollständig abgeschlossen</span>

      <vs-checkbox color="success" class="pr-2" v-if=" permissionComplete == true && (accounting.commission_complete === true || accounting.commission_complete === 1)" v-model="accounting.commission_complete" >Rückgängig machen</vs-checkbox>

      <vs-button color="warning" type="border" class="mb-4" :to="{name :'accountings'}">Abbrechen</vs-button>
      <vs-button class="ml-3 mb-4" @click="onClickSaveAccouting">Speichern</vs-button>
    </div>

    <vs-popup title="Produkt auswählen" :active.sync="popupArticleSearch">
      <p>
        <mg-select :options="articles"
                   track-by="id"
                   label="name"
                   @select="onSelectArticle">

        </mg-select>
      </p>
    </vs-popup>

    <product-detail-modal :product-id="selectedProductId" :active.sync="productDetailModalOpen"
                          @close="productDetailModalOpen=false"></product-detail-modal>

    <vs-popup  title="Angebot zuweisen"   :active.sync="isOfferAllocationPopupOpen">

      <vs-alert color="#ff0000" :active="offers.length == 0" icon="error" class="mb-4 small-alert" >
        Keine Angebote für den Kunden gefunden, welches den Status "akzeptiert" oder "im Projekt" hat.
      </vs-alert>

      <vs-row>
        <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8">
          <mg-select v-model="offerAllocationSelectedOfferId"  :options="offers" v-validate="'required'" data-vv-as="Angebot"   placeholder="Angebot auswählen" track-by="id"  label="id" @select="onOfferChange"  :custom-label="customOfferLabel">

            <template slot="singleLabel" slot-scope="{ option }">
              <strong>Angebot Nr. {{ option.invoice_number }} </strong><br><small>{{option.title}}</small>
            </template>

            <template slot="option" slot-scope="props">
                                    <span>
                                        {{props.option.invoice_number}} <small>({{props.option.title}} - {{formatDate(props.option.order_date)}})</small></span>
            </template>

          </mg-select>
        </vs-col>

        <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8" v-if="offerAllocationSelectedOfferId">
          <mg-select v-model="offerAllocationSelectedOfferItemId"  :options="offerItems" v-validate="'required'" name="offerItem" data-vv-as="Angebotsposition"   placeholder="Optional: Position auswählen" track-by="id"  label="id" :custom-label="customOfferItemLabel">

            <template slot="singleLabel" slot-scope="{ option }">
              <strong>{{ option.product_name }}</strong><br>
              <small>Angebotene Menge: {{option.amount}} {{getVolumeUnitNameById(parseInt(option.volume_unit))}}</small>
            </template>

            <template slot="option" slot-scope="props">
              <span>{{props.option.product_name}} ({{props.option.amount}} {{getVolumeUnitNameById(parseInt(props.option.volume_unit))}}) <br><small v-html="props.option.short_description">{{props.option.short_description}}</small></span>
            </template>

          </mg-select>
        </vs-col>
      </vs-row>

      <vs-row>
        <i class="mb-2">[ i ] Du kannst entweder ein <b>Angebot</b> oder eine <b>Angebotsposition</b> dieser Abrechnung hinzufügen. Wenn möglich immer Angebotspositionen hinterlegen.</i>
        <vs-button @click="selectOffer" :disabled="!offerAllocationSelectedOfferId">Angebot hinterlegen</vs-button>
      </vs-row>

    </vs-popup>


    <vs-popup title="Angebot" :active.sync="isOfferErrorPopupOpen">
      <vs-row>
        <div class="vs-alert con-icon offerError mb-4"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">error</i>Bitte wählen Sie einen Angebot aus. </div>

        <vs-button color="warning" type="border" class="mb-4 " @click="isOfferErrorPopupOpen = !isOfferErrorPopupOpen">Abbrechen</vs-button>
        <vs-button class="ml-3 mb-4" @click="saveAccounting">Trotzdem speichern</vs-button>
      </vs-row>
    </vs-popup>

    <vs-popup title="Kunde auswählen" :active.sync="popupClientSearch">
      <p>
        <mg-select v-model="accounting.client_id" :options="clients" name="client"
                   placeholder="Kunde auswählen"
                   @input="onSelectClient"
                   track-by="id" label="company_name" data-vv-as="Kunde" v-validate="'required'">
        </mg-select>
      </p>
    </vs-popup>


    <vs-popup title="Lizenz zuweisen" :active.sync="isLicenceAllocationPopupOpen">
      <vs-alert color="warning" :active="licences.length === 0" icon="error" class="mb-4 small-alert">
        Keine Lizenzen gefunden.
      </vs-alert>

      <vs-row>
        <vs-col vs-w="12" class="mb-8">
          <mg-select
              v-model="selectedLicenceId"
              :options="licences"
              placeholder="Lizenz auswählen"
              track-by="id"
              label="domain"
              :custom-label="(option) => `${option.domain} (${option.license_plan})`">
          </mg-select>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-button @click="selectLicence" :disabled="!selectedLicenceId">Lizenz zuweisen</vs-button>
      </vs-row>
    </vs-popup>


  </div>
</template>

<script>
import ApiService from "../../../api/index";
import staticOptions from "../../../mixins/static/options";
import PriceHelper from "../../../mixins/helper/price.helper";
import MgSelect from "../../../components/mg-select/MgSelect";
import TaskAllocation from "../../../components/commision/TaskAllocation";
import ArticleCustomPricesService from "../../../mixins/services/article-custom-prices.service";
import ProductDetailModal from "../../../components/product/ProductDetailModal";
import * as qs from "qs";
import moment from 'moment';
import Datepicker from '@sum.cumo/vue-datepicker';

export default {
  components: {ProductDetailModal, MgSelect, TaskAllocation, Datepicker},
  name: "contract-add",
  data() {
    return {
      customPriceService: new ArticleCustomPricesService(),
      accountingId: null,
      accounting: null,
      clients: [],
      fetched: false,
      popupArticleSearch: false,
      popupClientSearch: false,
      articles: [],
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
      TaskAllocationData: TaskAllocation.data().users,
      permissionComplete: false,
      selectedProductId: null,
      productDetailModalOpen: false,
      oriArticleUnitPrice: 0,
      isOfferAllocationPopupOpen: false,
      isOfferErrorPopupOpen: false,

      offers: [],
      offerItems: [],

      offerAllocationSelectedOfferId: null,
      offerAllocationSelectedOfferItemId: null,
      purchasePriceError: false,

      isLicenceAllocationPopupOpen: false, // Popup-Status
      licences: [], // Speichert die geladenen Lizenzen
      selectedLicenceId: null,
    }

  },
  created() {
    this.accountingId = this.$route.params.id;
    this.fetchInitialData();
  },
  computed: {
    unitPriceCalc() {
      var percentToAdd = 20;
      var percentOfUnitPrice = (percentToAdd / 100) * PriceHelper.parseCommaFloat(this.accounting.ek_price);
      return PriceHelper.floatPointToComma(PriceHelper.parseCommaFloat(this.accounting.ek_price) + PriceHelper.parseCommaFloat(percentOfUnitPrice) );
    },
    taxOptions() {
      return staticOptions.tax;
    },
    unitOptions() {
      if(this.accounting.is_hour_unit) {
        return staticOptions.units.filter(unit => unit.hour_unit);
      }

      return staticOptions.units;
    },
    currentUnit() {
      if(!this.accounting.volume_unit) {
        return null;
      }

      return staticOptions.units.find(unit => unit.value == this.accounting.volume_unit);
    },
    taxSum() {
      return PriceHelper.floatPointToComma(PriceHelper.taxPrice(PriceHelper.parseCommaFloat(this.accounting.unit_price), this.accounting.tax) * this.accounting.amount);
    },
    sum() {
      return PriceHelper.floatPointToComma(PriceHelper.parseCommaFloat(this.accounting.unit_price) * this.accounting.amount);
    },
    margeAbs() {
      return PriceHelper.floatPointToComma((PriceHelper.parseCommaFloat(this.accounting.unit_price) * this.accounting.amount) - (PriceHelper.parseCommaFloat(this.accounting.ek_price) * this.accounting.amount));
    },
    margePercent() {
      return this.calcMargePercent();
    },
    margePercentFloat() {
      return PriceHelper.floatPointToComma(this.calcMargePercent());
    },
    client() {
      return this.clients.find(client => client.id === this.accounting.client_id);
    },
    commisionComplete() {
      if(this.accounting.commission_complete == true) {
        return 1;
      } else {
        return 0;
      };
    },
    commisionAvailable() {
      if(this.accounting.commission_option == true) {
        return 1;
      } else {
        return 0;
      };
    },
    isEKPriceRequire() {

      if (    this.accounting.article.article_type_id === 4 ||
              this.accounting.article.article_type_id === 5 ||
              this.accounting.article.article_type_id === 9 ||
              this.accounting.article.article_type_id === 10) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    calcMargePercent() {
      var sum = PriceHelper.parseCommaFloat(this.accounting.unit_price) * this.accounting.amount;
      var marge =  sum - (PriceHelper.parseCommaFloat(this.accounting.ek_price) * this.accounting.amount);

      return  (marge / sum)*100;
    },
    unitName(value) {
      let name = staticOptions.units.find((item) => item.value == value);

      if(name === null || name === undefined) {
        return null;
      }

      return name.labelShort;
    },
    openProductDetailModal() {
      this.selectedProductId = this.accounting.article_id;
      this.productDetailModalOpen = true;
    },
    checkIfUserHasCompletingPermission() {
      var functionPermission = ApiService.post(`auth/functionPermission`, {permissionKey: "accounting_completion_return"} )
          .then(response => {
            if (response.data.status == "success") {
              this.permissionComplete = true;

            } else {
              this.permissionComplete = false;
            }
          });
    },
    fetchInitialData() {
      this.$vs.loading();
      this.fetched = false;

      const accounting = ApiService.get(`accountings/${this.accountingId}`);
      const clients = ApiService.get('clients');
      const contracts = ApiService.get('contracts');
      const articles = ApiService.get('articles');

      Promise.all([clients, contracts, accounting, articles]).then(result => {
        this.$vs.loading.close();

        if (result[0].status !== 200 || result[1].status !== 200) {
          this.$router.push({name: 'accountings'});
        }

        this.clients = result[0].data.result;
        this.accounting = result[2].data.result;
        this.articles = result[3].data.result;


        this.oriArticleUnitPrice = this.accounting.unit_price / this.accounting.unit_multiplicator;

        if(this.accounting.article) {
          this.accounting.ordernumber = this.accounting.article.ordernumber;
        }

        if(this.accounting.unit_price === 0 || this.accounting.unit_price === null) {
          this.accounting.unit_price = this.unitPriceCalc;
        }

        this.customPriceService.fetchCustomerPrices(this.accounting.client_id);

        this.checkIfUserHasCompletingPermission();
        this.fetched = true;
      });

    },
    onCheckCommissionComplete() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind sie sicher diese Aufgabenzuweisung vollständig abzuschließen?',
        accept: this.accaptCommissionComplete,
        acceptText: 'Bestätigen',
        cancelText: 'Abbrechen',
        cancel: this.cancelCommissionComplete
      })

    },
    accaptCommissionComplete() {
      this.$refs.TaskAllocationData.completeCommission()
    },
    cancelCommissionComplete() {
      this.accounting.commission_complete = false;
    },
    onClickSaveAccouting() {
      if (this.accounting.offer_id) {
        this.saveAccounting()
      } else {
        this.isOfferErrorPopupOpen = true
      }

    },
    saveAccounting() {

      let data = {
        client_id: this.accounting.client_id,
        article_id: this.accounting.article_id,
        workspace_id: this.accounting.workspace_id,
        at_least_date_accounting: this.accounting.at_least_date_accounting ? moment(this.accounting.at_least_date_accounting).format('Y-M-D') : null,
        amount: this.accounting.amount,
        tax: this.accounting.tax,
        volume_unit: this.accounting.volume_unit,
        unit_price: PriceHelper.parseCommaFloat(this.accounting.unit_price),
        ek_price: PriceHelper.parseCommaFloat(this.accounting.ek_price),
        article_name: this.accounting.article_name,
        short_description: this.accounting.short_description,
        contract_id: this.accounting.contract_id,
        contract_items_id: this.accounting.contract_items_id,
        contract_start: this.accounting.contract_start,
        contract_end: this.accounting.contract_end,
        accounting_start: this.accounting.accounting_start,
        accounting_end: this.accounting.accounting_end,
        commission_complete: this.commisionComplete,
        commission_option: this.commisionAvailable,
        is_hour_unit: this.accounting.is_hour_unit,
        unit_multiplicator: this.accounting.unit_multiplicator,
        offer_item_id: this.accounting.offer_item_id,
        offer_id: this.accounting.offer_id,
        b2b_licence_id: this.accounting.b2b_licence_id,
      };


        return ApiService.put(`accountings/${this.accountingId}`, data).then(() => {
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });

          this.isOfferErrorPopupOpen = false;

          if(this.accounting.commission_complete == true ||this.accounting.commission_complete == 1) {
            this.$refs.TaskAllocationData.completeCommission()
          }
          this.saveTaskAllocation();
          return true

        }).catch((error) => {
          this.$vs.notify({
            title: 'Fehler',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          return false;
        });
    },
    saveTaskAllocation() {
      // ToDo: Auf PUT umstellen, wird aber ein CORS Problem angezeigt.
      ApiService.post(`task-allocation-user-list/accounting/${this.accountingId}`, this.$refs.TaskAllocationData.users
      ).then(response => {
        if (response.status === 200) {
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Aufgabenzuweisung wurde erfolgreich gespeichert.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.$refs.TaskAllocationData.fetchUser()
        }
      }). catch((error) => {

        let errorMessage = error.message;
        if(error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        if(errorMessage.length >= 1) {
          var i;
          for (i = 0; i < errorMessage.length; i++) {
            this.$vs.notify({
              title: 'Fehler',
              text: errorMessage[i],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        } else {
          this.$vs.notify({
            title: 'Fehler',
            text: errorMessage,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }

      })
    },
    clientCustomLabel(option) {
      return option.company_name;
    },
    openArticleSearch() {
      this.popupArticleSearch = true;
    },
    getUnitOptionById(id) {
      return this.unitOptions.find(unit => unit.value === id);
    },
    onChangeVolumeUnit() {
      const unit = this.getUnitOptionById(this.accounting.volume_unit);

      if(unit) {
        this.accounting.unit_multiplicator = unit.multiplicator;
        this.accounting.unit_price = this.oriArticleUnitPrice * unit.multiplicator;

      }
    },
    onSelectArticle(article) {
      const customerPrice = this.customPriceService.getPriceByArticleId(article.id);


      this.accounting.article_id = article.id;
      this.accounting.short_description = article.description;
      this.accounting.article_name = article.name;
      this.accounting.ek_price = article.purchase_price;
      this.accounting.unit_price = PriceHelper.floatPointToComma(article.price);
      this.oriArticleUnitPrice = JSON.parse(JSON.stringify(article.price));

      if(customerPrice !== null) {
        this.accounting.unit_price = customerPrice.price;
        this.oriArticleUnitPrice = JSON.parse(JSON.stringify(customerPrice.price));
      }

      this.accounting.is_hour_unit = article.is_hour_unit;
      this.accounting.unit_multiplicator = 1;

      const unit = this.getUnitOptionById(this.accounting.volume_unit);

      if(unit && unit.multiplicator ) {
        this.accounting.unit_multiplicator = unit.multiplicator;
        this.oriArticleUnitPrice =  this.oriArticleUnitPrice / unit.multiplicator;
      }

      this.accounting.ordernumber = article.ordernumber;
      this.accounting.tax = article.tax;
      this.accounting.volume_unit = parseInt(article.unit);

      this.popupArticleSearch = false;

      article.categories.forEach(category => {
        if (category.name === 'SSL-Zertifikate' || category.name === 'Fremdleistung' || category.name === 'Digitales Produkt' || category.name === 'Server/Hosting') {
          this.purchasePriceError = true;
        } else {
          this.purchasePriceError = false;
        }
      })
    },
    fetchOffers() {
      let queryParams = {
        filter: [
          {
            field: 'type.invoice_type',
            expression: 'exact',
            value: 'offer'
          },
          {
            field: 'client_id',
            expression: 'exact',
            value: this.accounting.client_id
          },
          {
            expression: 'group',
            filter: [
              {
                field: 'status',
                expression: 'exact',
                value: '16',
                operator: 'or',
              },
              {
                field: 'status',
                expression: 'exact',
                value: '18',
                operator: 'or',
              },
              {
                field: 'status',
                expression: 'exact',
                value: '19',
                operator: 'or',
              }
            ]
          }
        ]
      };

      ApiService.get('orders', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.offers = response.data.result;
        return true;
      })
    },
    openOfferAllocationPopup(){
      this.fetchOffers();
      this.isOfferAllocationPopupOpen = true;
    },
    customOfferLabel(option) {
      return `${option.invoice_number} - ${option.title} - ${option.order_date}`;
    },
    customOfferItemLabel(option) {
      return `${option.product_name} - ${option.title} - ${option.short_description}`;
    },
    onOfferChange(item){
      //   this.newCommissionsData.client_id = item.client_id
      this.offerItems = [];
      this.offerItems = item.items;
    },
    getVolumeUnitNameById(id) {
      if(typeof id == 'undefined' || id == null) {
        return "X";
      }
      const units = staticOptions.units.find(item => item.value === id);
      if(typeof units == 'undefined') {
        return "X";
      }
      return units.label

    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    clickDeleteOfferAllocation() {
      this.accounting.offer_id = null;
      this.saveAccounting()
    },
    clickDeleteOfferItemAllocation() {
      this.accounting.offer_item_id = null;
      this.saveAccounting()
    },
    selectOffer(){
      if(this.offerAllocationSelectedOfferItemId != null) {
        this.accounting.offer_item_id = this.offerAllocationSelectedOfferItemId;
        this.offerAllocationSelectedOfferItemId = null;
      } else if(this.offerAllocationSelectedOfferId != null) {
        this.accounting.offer_id = this.offerAllocationSelectedOfferId;
        this.offerAllocationSelectedOfferId = null;
      } else {
        this.$vs.notify({
          title: 'Fehler',
          text: "Konnte nicht gespeichert werden!",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }


      this.isOfferAllocationPopupOpen = false;
      this.saveAccounting().then((response) => {
        this.isOfferAllocationPopupOpen = false;
        this.fetchInitialData();
      }).catch((response) => {
        this.$vs.notify({
          title: 'Fehler',
          text: "Konnte nicht gespeichert werden",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    openClientSearch() {
      this.popupClientSearch = true;
    },
    onSelectClient(clientId) {
      for(let i = 0;i < this.clients.length; i++) {
        if (this.clients[i].id === clientId) {
          this.accounting.client.company_name = this.clients[i].company_name;
          this.accounting.client.email = this.clients[i].email;
          this.accounting.client.customer_number = this.clients[i].customer_number;
          this.accounting.client.address_line_1 = this.clients[i].address_line_1;
          this.accounting.client.address_line_2 = this.clients[i].address_line_2;
          this.accounting.client.zip_code = this.clients[i].zip_code;
        }
      }
      this.popupClientSearch = false;
    },
    fetchLicences() {
      this.$vs.loading();

      ApiService.get('b2b-sellers/licence/list', {
        params: {
          per_page: 200,
          page: 1,
        }
      }).then((response) => {
        if (response.data.status === "success") {
          this.licences = response.data.result;
        } else {
          this.$vs.notify({
            title: "Fehler",
            text: "Lizenzen konnten nicht geladen werden.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
        this.$vs.loading.close();
      }).catch(() => {
        this.$vs.notify({
          title: "Fehler",
          text: "Lizenzen konnten nicht geladen werden.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        this.$vs.loading.close();
      });
    },

    openLicenceAllocationPopup() {
      this.fetchLicences();
      this.isLicenceAllocationPopupOpen = true;
    },

    selectLicence() {
      if (!this.selectedLicenceId) {
        this.$vs.notify({
          title: "Fehler",
          text: "Bitte eine Lizenz auswählen.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return;
      }

      this.accounting.b2b_licence_id = this.selectedLicenceId;
      this.isLicenceAllocationPopupOpen = false;

      // Speichern der Lizenzzuweisung in der Datenbank
      this.saveAccounting();
    },
  },
  filters: {},
}
</script>

<style lang="scss">
.accounting-details {
  .quillWrapper {
    .ql-editor {
      max-height: 35rem;
      min-height: 5rem;
    }
  }
}
span.commission_complete {
  color: green;
  font-size: 17px;
  display: block;
  position: relative;
  padding-top: 15px;
  font-weight: 600;
}
.color-red {
  color:red;
}
.color-green {
  color:green;
}
.offerError {
  background-color: #e24733;
  min-height: 40px;
  width: 100%;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  color: white;
}
.border-red {
  .vs-con-input {
    .vs-inputx  {
      &.vs-input--input {
        &.normal {
          border-color: red !important;
        }
      }
    }
  }
}
</style>

